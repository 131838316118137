import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { BulkReport, ApiResponse } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class BulkReports {
  reports: BulkReport[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    const records = []; // Initial Values
    for (const item of records) {
      this.reports.push(new BulkReport(item));
    }
    let queryString = `?sort=-createdAt`;
    queryString += `createdBy,updatedBy`;
    this.recordRetrieve(queryString).then((res) => {
      this.reports = res.payload;
      console.log(res);
    });
  }

  query(params?: any) {
    if (!params) {
      return this.reports;
    }
    return this.reports.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: BulkReport) {
    const index = this.reports.findIndex((rate) => rate.id === record.id);
    this.reports.splice(index, 1);
  }

  delete(record: BulkReport) {
    this.reports.splice(this.reports.indexOf(record), 1);
  }

  async recordRetrieve(queryString: string): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/expenses/pmt/transactions/all${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }
}
