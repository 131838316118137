import { Customer } from '../crm/customer';
import { Terminal } from '../location/terminal';
import { Staff } from '../people/staff';
import { PmtSchedule } from '../pmt/pmt-schedule';
import { PmlRouting } from './pml-routing';
import { PmlPackage } from './pml-package';

export class PmlParcel {
  _id: string;
  id: string;
  code: string;
  name: string;
  pmlPackage: PmlPackage;
  pmlRoutings: Array<PmlRouting>;
  pmtSchedule: PmtSchedule;
  sender: Customer;
  recipient: Customer;
  urgency: number;
  distance: number;
  mass: number;
  volume: number;
  worth: number;
  isFragile: boolean;
  isPerishable: boolean;
  isCombustible: boolean;
  isOdiferous: boolean;
  isLiquid: boolean;
  isUnique: boolean;
  description: string;
  pmlTerminalFrom: Terminal;
  pmlTerminalTo: Terminal;
  pmlTerminalStore: Terminal;
  travelHour: number;
  departureDate: Date;
  expectedDate: Date;
  billingType: 'PRE_PAID'|'POST_PAID'|'DEDICATED';
  costEstimate: number;
  costPayable: number;
  paymentMethod: 'GATEWAY'|'POS'|'CASH'|'CHEQUE'|'TRANSFER'|'USSD';
  paymentGateway: 'UNIONBANK'|'PAYSTACK';
  paymentStatus: 'PENDING'|'SUCCESSFUL'|'FAIL';
  paymentBy: Staff;
  paymentDate: Date;
  deliveryDate: Date;
  deliveryType: 'HOME'|'TERMINAL';
  deliveryAddress: string;
  deliveryStaff: Staff;
  deliveryAgent: Staff;
  packaged: boolean;
  deliveryStatus: 'PENDING'|'WAYBILLED'|'SHIPPED'|'ARRIVED'|'DISPATCHED'|'DELIVERED';
  routing: string;
  routingStatus: 'STORE' | 'TRANSIT';
  waybilledDate: Date;
  waybilledBy: Staff;
  shippedDate: Date;
  shippedBy: Staff;
  dispatchedDate: Date;
  dispatchedBy: Staff;
  dispatcher: Staff;
  arrivedDate: Date;
  arrivedBy: Staff;
  deliveredDate: Date;
  deliveredBy: Staff;
  identification: string;
  remark: string;
  recipientConfirm: boolean;
  recipientConfirmDate: Date;
  recipientConfirmRemark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;


  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }

}

export interface PmlParcel {
  id: string;
  code: string;
  name: string;
  pmlPackage: PmlPackage;
  pmlRoutings: Array<PmlRouting>;
  pmtSchedule: PmtSchedule;
  sender: Customer;
  recipient: Customer;
  urgency: number;
  distance: number;
  mass: number;
  volume: number;
  worth: number;
  isFragile: boolean;
  isPerishable: boolean;
  isCombustible: boolean;
  isOdiferous: boolean;
  isLiquid: boolean;
  isUnique: boolean;
  description: string;
  pmlTerminalFrom: Terminal;
  pmlTerminalTo: Terminal;
  pmlTerminalStore: Terminal;
  travelHour: number;
  departureDate: Date;
  expectedDate: Date;
  billingType: 'PRE_PAID'|'POST_PAID'|'DEDICATED';
  costEstimate: number;
  costPayable: number;
  paymentMethod: 'GATEWAY'|'POS'|'CASH'|'CHEQUE'|'TRANSFER'|'USSD';
  paymentGateway: 'UNIONBANK'|'PAYSTACK';
  paymentStatus: 'PENDING'|'SUCCESSFUL'|'FAIL';
  paymentBy: Staff;
  paymentDate: Date;
  deliveryDate: Date;
  deliveryType: 'HOME'|'TERMINAL';
  deliveryAddress: string;
  deliveryStaff: Staff;
  deliveryAgent: Staff;
  deliveryStatus: 'PENDING'|'WAYBILLED'|'SHIPPED'|'ARRIVED'|'DISPATCHED'|'DELIVERED';
  routing: string;
  routingStatus: 'STORE' | 'TRANSIT';
  waybilledDate: Date;
  waybilledBy: Staff;
  shippedDate: Date;
  shippedBy: Staff;
  dispatchedDate: Date;
  dispatchedBy: Staff;
  dispatcher: Staff;
  arrivedDate: Date;
  arrivedBy: Staff;
  deliveredDate: Date;
  deliveredBy: Staff;
  identification: string;
  remark: string;
  recipientConfirm: boolean;
  recipientConfirmDate: Date;
  recipientConfirmRemark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

}
