import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, ApiResponse, Terminal, State, County, Transfer } from '../../models';
import { ApiService, EnvService } from '../../services';
import { deepPropsExist } from '../../helpers';
import { Terminals } from '../location/terminals';
import { States } from '../location/states';
import { Counties } from '../location/counties';


@Injectable()
export class Transfers {

  transfers: Transfer[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=asset,material,product,vehicle,sender`;
    queryString += `,recipient,storeFrom,storeTo,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.transfers = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.transfers;
    }
    return this.transfers.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Transfer) {
    this.transfers.push(record);
  }

  delete(record: Transfer) {
    const index = this.transfers.findIndex(stock => stock.id === record.id);
    this.transfers.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/transfers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Transfer): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/transfers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=asset,material,product,vehicle,sender`;
          queryString += `,recipient,storeFrom,storeTo,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Transfer, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/transfers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=asset,material,product,vehicle,sender`;
          queryString += `,recipient,storeFrom,storeTo,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Transfer): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/transfers/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Transfer): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/transfers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
