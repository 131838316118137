import { Staff } from '../people/staff';
import { Customer } from "../crm/customer";
import { Sale } from "./sale";
import { Image } from "./image";


export class EcommerceCategory {
    id: string;
    code: string;
    name: string;
    type: "PHYSICAL" | "DIGITAL" | "SERVICE";
    description: string;
    parent: EcommerceCategory;
    image: Image;
    createdBy: Staff | Customer;
    createdAt?: Date;
    updatedBy: Staff | Customer;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface EcommerceCategory {
    id: string;
    code: string;
    name: string;
    type: "PHYSICAL" | "DIGITAL" | "SERVICE";
    description: string;
    parent: EcommerceCategory;
    image: Image;
    createdBy: Staff | Customer;
    createdAt?: Date;
    updatedBy: Staff | Customer;
    updatedAt?: Date;
}
