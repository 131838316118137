import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, Staff, EcommerceStore, Supplier, Product } from '../../models';
import { EcommerceStores } from './ecommerce-stores';
import { ApiService, EnvService } from '../../services';
import { Staffs } from '../people/staffs';
import { deepPropsExist } from '../../helpers';
import { Suppliers } from './suppliers';

@Injectable()
export class Products {

  products: Product[] = [];
  staffRecords: Staff[] = [];
  supplierRecords: Supplier[] = [];
  storeRecords: EcommerceStore[] = [];

  constructor(
      private apiService: ApiService,
        private env: EnvService,
        private suppliers: Suppliers,
        private staffs: Staffs,
        private stores: EcommerceStores,
    ) {
    this.staffRecords = this.staffs.query();
    this.supplierRecords = this.suppliers.query();
    this.storeRecords = this.stores.query();

    let queryString = `?sort=name`;
    queryString += `&populate=products,assets,staff,store,supplier`;
    this.recordRetrieve(queryString).then(res => { this.products = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.products;
    }
    return this.products.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Product) {
    this.products.push(record);
  }

  delete(record: Product) {
    const index = this.products.findIndex(route => route.id === record.id);
    this.products.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ecommerce/products${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Product): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/ecommerce/products`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
              if (res.success && res.payload) {
                this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: Product, payload): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/ecommerce/products/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
              if (res.success) {
                  this.delete(record);
                  console.log(res.payload);
                  this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: Product): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ecommerce/products/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Product): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ecommerce/products/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  private modifiedPayload(payload: any) {
    const staff = deepPropsExist(payload, 'staff') && payload.staff !== null
    ? this.staffRecords.find(staff => staff.id === payload.staff) : null;
    const supplier = deepPropsExist(payload, 'supplier') && payload.supplier !== null
    ? this.supplierRecords.find(supplier => supplier.id === payload.supplier) : null;
    const store = deepPropsExist(payload, 'store') && payload.store !== null
    ? this.storeRecords.find(store => store.id === payload.store) : null;
    // const items = payload.items.map(item => {
    //     const product = this.productRecords.find(prod => prod.id === item.product);
    //     const newItem = {...item, ...{product}};
    //     return newItem;
    // });

    return {...payload, ...{supplier, staff, store}};
  }

}
