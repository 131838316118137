export interface PmtReport {
    id?: string;
    terminal?: string; 
    totalCostEstimate?: number;
    totalCostPayable?: number;
    totalMass?: number;
    totalCount?: number;
    totalQuantity?: number;
    totalVolume?: number;
    totalWorth?: number;
}

export class PmtReport {
    id?: string;
    terminal?: string; 
    totalCostEstimate?: number;
    totalCostPayable?: number;
    totalMass?: number;
    totalCount?: number;
    totalQuantity?: number;
    totalVolume?: number;
    totalWorth?: number;

    constructor(fields: any){
        for(let f of fields){
            this[f] = fields[f];
        }
    }
}