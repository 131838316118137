import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiResponse} from '../../models';
import {ApiService, EnvService} from '../../services';

export interface PmtReport {
    'id': string;
    'totalPassengers': number;
    'totalGrossIncome': number;
    'totalFuelAmount': number;
    'totalDriverAllowance': number;
    'totalServiceCharge': number;
    'totalRepayment': number;
    'totalNetIncomeExpected': number;
    'totalNetIncomeActual': number;
    'totalGrossIncomeExpected': number;
    'totalGrossIncomeEnterprise': number;
    'totalNetIncomeEnterprise': number;
}

@Injectable()
export class PmtReports {

    private records: PmtReport[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=terminalFrom,terminalTo,category,createdBy,updatedBy`;
    }

    query(params?: any) {
        if (!params) {
            return this.records;
        }
        return this.records.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmtReport) {
        this.records.push(record);
    }

    delete(record: PmtReport) {
        const index = this.records.findIndex(route => route.id === record.id);
        this.records.splice(index, 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/reports/generate${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }

    async recordCreate(record: PmtReport): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/reports/generate`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmtReport, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/reports/generate/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=terminalFrom,terminalTo,category,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmtReport): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/reports/generate/${record.id}`;
        const proRes = this.apiService.patchApi(url, {}).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmtReport): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/reports/generate/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

}
