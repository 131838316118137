import { Component, OnInit } from '@angular/core';
import { Staff } from '../../../models';
import { AuthService } from '../../../services';
import { safeGet } from '../../../helpers';

@Component({
  selector: 'app-schedule-manager',
  templateUrl: './schedule-manager.component.html',
  styleUrls: ['./schedule-manager.component.scss']
})
export class ScheduleManagerComponent implements OnInit {

  user: Staff;
  isAdmin: boolean = false;

  constructor(private authService: AuthService) {
    this.user = this.authService.getUser();
    const role = safeGet(this.user.role, 'name');
    if (role === 'PMT_TERMINAL_SUPERVISOR' || role === 'PMT_TERMINAL_MANAGER'){
      this.isAdmin = true;
    }
  }

  ngOnInit() {
  }

}
