import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, Payroll } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Payrolls {

  payrolls: Payroll[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=payrollDetails,voucher,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.payrolls = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.payrolls;
    }
    return this.payrolls.filter((payroll) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = payroll[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return payroll;
            } else if (field === params[key]) {
              return payroll;
            }
          }
      }
      return null;
    });
  }

  add(record: Payroll) {
    this.payrolls.push(record);
  }

  delete(record: Payroll) {
    this.payrolls.splice(this.payrolls.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/payrolls${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: Payroll): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/payrolls`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=payrollDetails,voucher,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Payroll, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/payrolls/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=payrollDetails,voucher,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Payroll): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/payrolls/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Payroll): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/payrolls/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
