import { Expense } from "./expense";
import { Terminal } from "..";

export class BulkReport {
  id?: string;
  terminal: Terminal;
  report: Array<{
    income: string[];
    expense: Expense[];
    date: Date;
    totalIncome: number;
    totalExpense: number;
    balance: number;
    banking: any;
    remainingBalance: any;
    deposit: any;
    bankingTotal: any;
  }>;
  income: string[];
  expense: Expense[];
  date: Date;
  totalIncome: number;
  totalExpense: number;
  balance: number;
  banking: any;
  remainingBalance: any;
  deposit: any;
  bankingTotal: any;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface BulkReport {
  id?: string;
  terminal: Terminal;
  report: Array<{
    income: string[];
    expense: Expense[];
    date: Date;
    totalIncome: number;
    totalExpense: number;
    balance: number;
    banking: any;
    remainingBalance: any;
    deposit: any;
    bankingTotal: any;
  }>;
  income: string[];
  expense: Expense[];
  date: Date;
  totalIncome: number;
  totalExpense: number;
  balance: number;
  banking: any;
  remainingBalance: any;
  deposit: any;
  bankingTotal: any;
}
