import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Expense, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Expenses {

    expenses: Expense[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=pmlTerminal,voucher,accountHeading`;
        this.recordRetrieve(queryString).then(res => { this.expenses = res.payload; console.log(res) });
      }

  query(params?: any) {
    if (!params) {
      return this.expenses;
    }
    return this.expenses.filter((bankAccount) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = bankAccount[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return bankAccount;
          } else if (field === params[key]) {
            return bankAccount;
          }
        }
      }
      return null;
    });
  }

  add(record: Expense) {
    this.expenses.push(record);
  }

  delete(record: Expense) {
    this.expenses.splice(this.expenses.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/expenses${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: Expense): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/expenses`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Expense, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/expenses/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,voucher,accountHeading`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Expense): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/expenses/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Expense): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/expenses/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
