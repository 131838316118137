import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthGuard } from './services';
import { ScheduleManagerComponent } from './pages/schedule/schedule-manager/schedule-manager.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-otp',
    component: LoginOtpComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      },

    { 
        path: 'customers',
        loadChildren: './pages/customer/customer.module#CustomerModule'
      },
      {
        path: 'pmt-boarding',
        loadChildren: './pages/pmt-boarding/pmt-boarding.module#PmtBoardingModule'
      },
      // {
      //   path: 'pmt-schedules',
      //   loadChildren: './pages/schedule/schedule.module#ScheduleModule'
      // },
      {
        path: 'pmt-schedules', component: ScheduleManagerComponent,
        children: [{ path: '', loadChildren: './pages/schedule/schedule.module#ScheduleModule' }],
      },

      {
        path: 'pmt-reservations',
        loadChildren: './pages/pmt-reservations/pmt-reservations.module#PmtReservationsModule'
      },

      {
        path: 'pml-shipments',
        loadChildren: './pages/pml-shipments/pml-shipments.module#PmlShipmentsModule'
      },

      {
        path: 'pmt-locations',
        loadChildren: './pages/pmt-locations/pmt-locations.module#PmtLocationsModule'
      },

      {
        path: 'pmt-vehicles',
        loadChildren: './pages/pmt-vehicles/pmt-vehicles.module#PmtVehiclesModule'
      },
      {
        path: 'pmt-hiring',
        loadChildren: './pages/pmt-hiring/pmt-hiring.module#PmtHiringModule'
      },

      {
        path: "report",
        loadChildren: './pages/report/report.module#ReportModule'
      },

      {
        path: 'bulk-report',
        loadChildren: './pages/bulk-report/bulk-report.module#BulkReportModule'
      },
      {
        path: 'entreprises',
        loadChildren: './pages/entreprise/entreprise.module#EntrepriseModule'
      },
      {
        path: 'components',
        loadChildren:
          './pages/components/components.module#ComponentsPageModule'
      },
      {
        path: 'forms',
        loadChildren: './pages/forms/forms.module#Forms'
      },
      {
        path: 'tables',
        loadChildren: './pages/tables/tables.module#TablesModule'
      },
      {
        path: 'maps',
        loadChildren: './pages/maps/maps.module#MapsModule'
      },
      {
        path: 'widgets',
        loadChildren: './pages/widgets/widgets.module#WidgetsModule'
      },
      {
        path: 'charts',
        loadChildren: './pages/charts/charts.module#ChartsModule'
      },
      {
        path: 'calendar',
        loadChildren: './pages/calendar/calendar.module#CalendarModulee'
      },
      {
        path: 'user-profile',
        loadChildren: './pages/user-profile/user-profile.module#UserProfileModule',
      },
      {
        path: '',
        loadChildren:
          './pages/pages/user-profile/user-profile.module#UserModule'
      },
      {
        path: '',
        loadChildren: './pages/pages/timeline/timeline.module#TimelineModule'
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'pages',
        loadChildren: './pages/pages/pages.module#PagesModule'
      }
    ]
  }
];
