import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Vehicle, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Vehicles {

  records: Vehicle[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=name`;
    queryString += `&subsidiary=PMT&assignmentStatus=ASSIGNED`;
    queryString += `&populate=currentPartner`;
    this.recordRetrieve(queryString).then(res => {
      this.records = res.payload; console.log(res) 
    });
  }


  query(params?: any) {
    if (!params) {
      return this.records;
    }
    return this.records.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Vehicle) {
    this.records.push(record);
  }

  delete(record: Vehicle) {
    this.records.splice(this.records.indexOf(record), 1);
  }

  filterAssignedVehicle(arrayOfVehicles: Array<Vehicle>): Array<Vehicle> {
    const t = arrayOfVehicles.filter((v: Vehicle) => v.currentPartner != null );
    return t;
  }
  
  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/vehicles${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }
 
  async recordCreate(record: Vehicle): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/vehicles`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=currentStaff,currentPartner,assignments`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: Vehicle, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/vehicles/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=currentStaff,currentPartner,assignments`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

  async recordPatch(record: Vehicle): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/vehicles/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Vehicle): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/vehicles/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
