import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmtVehicle, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()
export class PmtVehicles {
  pmtVehicles: PmtVehicle[] = [];
  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=name`;
    queryString += `&populate=currentStaff,currentPartner,assignments`;
    // this.recordRetrieve(queryString).then(res => { this.pmtVehicles = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.pmtVehicles;
    }
    return this.pmtVehicles.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }
  add(record: PmtVehicle) {
    this.pmtVehicles.push(record);
  }
  delete(record: PmtVehicle) {
    this.pmtVehicles.splice(this.pmtVehicles.indexOf(record), 1);
  }
  filterAssignedPmtVehicle(arrayOfPmtVehicles: Array<PmtVehicle>): Array<PmtVehicle> {
    const t = arrayOfPmtVehicles.filter((v: PmtVehicle) => v.currentPartner != null );
    return t;
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/vehicles${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtVehicle): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/vehicles`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=currentStaff,currentPartner,assignments`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordUpdate(record: PmtVehicle, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/vehicles/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=currentStaff,currentPartner,assignments`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordPatch(record: PmtVehicle): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/vehicles/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
  async recordDelete(record: PmtVehicle): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/vehicles/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
