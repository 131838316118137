import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxVectorMapModule } from 'devextreme-angular';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VectorMapComponent1 } from './vector-map/vector-map.component';
import { NgbdModalBasic } from './modal/modal.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { FileInputComponent } from './file-input/file-input.component';
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { FixedPluginComponent } from './fixed-plugin/fixed-plugin.component';
import {AdvanceTableComponent} from './advance-table/advance-table.component';
import {RightSidebarComponent} from './right-sidebar/right-sidebar.component';
import {DataTablesModule} from 'angular-datatables';
import {AppanalystModalComponent} from './appanalyst-modal/appanalyst-modal.component';
import {CustomerFormComponent} from './customer-form/customer-form.component';
import {SeatPositionsComponent} from './seat-positions/seat-positions.component';
import { LoadingComponent } from './loading/loading.component';
import { ReportSheetComponent } from './report-sheet/report-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    DxVectorMapModule,
    DataTablesModule
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    VectorMapComponent1,
    NgbdModalBasic,
    ImageUploadComponent,
    FileInputComponent,
    PictureUploadComponent,
    FixedPluginComponent,
    AdvanceTableComponent,
    RightSidebarComponent,
    AppanalystModalComponent,
    CustomerFormComponent,
    SeatPositionsComponent,
    LoadingComponent,
    ReportSheetComponent
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    VectorMapComponent1,
    NgbdModalBasic,
    ImageUploadComponent,
    PictureUploadComponent,
    FileInputComponent,
    FixedPluginComponent,
    AdvanceTableComponent,
    RightSidebarComponent,
    AppanalystModalComponent,
    CustomerFormComponent,
    SeatPositionsComponent,
    LoadingComponent,
    ReportSheetComponent
  ]
})
export class ComponentsModule {}
