import { Expense } from "./expense";

export class Report {
  id?: string;
  income: string[];
  banking: string[];
  expense: Expense[];
  date: Date;

  totalIncome: number;
  totalExpense: number;
  balance: number;
  bankingTotal: number;
  remainingBalance: number;
  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface Report {
  id?: string;
  income: string[];
  banking: string[];
  expense: Expense[];
  date: Date;

  totalIncome: number;
  totalExpense: number;
  balance: number;
  bankingTotal: number;
  remainingBalance: number;
}
