import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export enum APP_MODE  {
  LIVE = 'LIVE',
  TEST = 'TEST',
}

@Injectable({
  providedIn: 'root'
})

export class EnvService {
  private appMode: string = APP_MODE.LIVE;
  private API_URL: string = environment.CURRENT_URL;

  constructor() {
  }

  switchAppMode(mode: APP_MODE) {
    this.appMode = mode;
    if(this.appMode === 'LIVE'){
      this.API_URL = environment.CURRENT_URL;
    }else{
      this.API_URL = environment.TEST_URL;
    }
  }

  getAppMode(): string{
    return this.appMode;
  }

  //coming soon

  getCurrentUrl(): string{
    return this.API_URL;
  }
}
