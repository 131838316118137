import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Hub, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()
export class Hubs {

    hubs: Hub[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=name`;
        queryString += `&populate=states,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.hubs = res.payload; console.log(res) });
      }

    query(params?: any) {
        if (!params) {
            return this.hubs;
        }
        return this.hubs.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: Hub) {
        this.hubs.push(record);
    }

    delete(record: Hub) {
        this.hubs.splice(this.hubs.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/hubs${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
        return await proRes.toPromise();
      }

      async recordCreate(record: Hub): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/hubs`;
        const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              console.log('recordCreate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=states,createdBy`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
      }
    
      async recordUpdate(record: Hub, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/hubs/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
            if (res.success && res.payload) {
              this.delete(record);
              console.log('recordUpdate() successful =>', res.payload);
              let queryString = `?_id=${res.payload.id}`;
              queryString += `&populate=states,createdBy,updatedBy`;
              this.recordRetrieve(queryString).then(res => {
                this.add(res.payload[0])
              })
            }
            return res;
          }));
        return await proRes.toPromise();
      }
    
    async recordPatch(record: Hub): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/hubs/${record.id}`;
        const proRes = this.apiService.patchApi(url, {}).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            return res;
          }));
        return await proRes.toPromise();
      }
    
      async recordDelete(record: Hub): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/hubs/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            return res;
          }));
        return await proRes.toPromise();
      }
    }
    