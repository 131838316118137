import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, ApiResponse, Terminal, State, County } from '../../models';
import { ApiService, EnvService } from '../../services';
import { deepPropsExist } from '../../helpers';
import { Terminals } from '../location/terminals';
import { States } from '../location/states';
import { Counties } from '../location/counties';


@Injectable()
export class Stores {

  stores: Store[] = [];
  terminalRecords: Terminal[] = [];
  stateRecords: State[] = [];
  countyRecords: County[] = [];

  constructor(
      private apiService: ApiService,
        private env: EnvService,
        private terminals: Terminals,
        private states: States,
        private counties: Counties
        ) {
    const records = []; // Initial Values
    for (const item of records) {
      this.stores.push(new Store(item));
    }
    this.recordRetrieve();
    this.terminalRecords = this.terminals.query();
    this.stateRecords = this.states.query();
    this.countyRecords = this.counties.query();

    let queryString = `?sort=-createdAt`;
    queryString += `&populate=terminal,county,state,head,staffList,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.stores = res.payload; console.log(res) });
  }


  query(params?: any) {
    if (!params) {
      return this.stores;
    }
    return this.stores.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Store) {
    this.stores.push(record);
  }

  delete(record: Store) {
    const index = this.stores.findIndex(store => store.id === record.id);
    this.stores.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stores${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Store): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/erp/stores`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
              if (res.success && res.payload) {
                  this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: Store, payload): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/erp/stores/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
              if (res.success) {
                  this.delete(record);
                  this.add(this.modifiedPayload(res.payload));
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: Store): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stores/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Store): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stores/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  private modifiedPayload(payload: any) {
    const terminal = deepPropsExist(payload, 'terminal') && payload.ternimal !== null
    ? this.terminalRecords.find(terminal => terminal.id === payload.terminal) : null;
    const state = deepPropsExist(payload, 'state') && payload.state !== null
    ? this.stateRecords.find(state => state.id === payload.state) : null;
    const county = deepPropsExist(payload, 'county') && payload.county !== null
    ? this.countyRecords.find(county => county.id === payload.county) : null;

    return {...payload, ...{county, terminal, state }};
  }

}
