import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiResponse, PosTransaction } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PosTransactions {

  posTransactions: PosTransaction[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=terminal`;
    this.recordRetrieve(queryString).then(res => { this.posTransactions = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.posTransactions;
    }
    return this.posTransactions.filter((payroll) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = payroll[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return payroll;
          } else if (field === params[key]) {
            return payroll;
          }
        }
      }
      return null;
    });
  }

  add(record: PosTransaction) {
    this.posTransactions.push(record);
  }

  delete(record: PosTransaction) {
    this.posTransactions.splice(this.posTransactions.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/bank-transactions/pos${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }
}
