import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { PmtRoute, ApiResponse } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class PmtRoutes {
  private records: PmtRoute[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    // let queryString = `?sort=-createdAt`;
    // queryString += `&populate=terminalFrom,terminalTo,category,createdBy,updatedBy`;
    // this.recordRetrieve(queryString).then(res => { this.records = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.records;
    }
    return this.records.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: PmtRoute) {
    this.records.push(record);
  }

  delete(record: PmtRoute) {
    const index = this.records.findIndex((route) => route.id === record.id);
    this.records.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-routes${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtRoute): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-routes`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminalFrom,terminalTo,category,createdBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmtRoute, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-routes/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminalFrom,terminalTo,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: PmtRoute): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-routes/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: PmtRoute): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-routes/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
