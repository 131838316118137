import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlStaff, ApiResponse, Terminal, Office } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PmlStaffs {

    pmlStaffs: PmlStaff[] = [];
    sortTerminal: Terminal['id'] = '';
    sortSubsidiary: string = '';
    sortOffice: Office['id'] = '';

    constructor(private apiService: ApiService,
                private env: EnvService) {
        let queryString = `?populate=office,terminal,currentVehicle,role.permissions,state,county`;
        this.recordRetrieve(queryString).then(res => { this.pmlStaffs = res.payload; console.log(res) });
    }

    query(params?: any) {
        if (!params) {
            return this.pmlStaffs;
        }
        return this.pmlStaffs.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmlStaff) {
        // this.pmlStaffs.push(record);
        this.pmlStaffs.unshift(record);
    }

    delete(record: PmlStaff) {
        this.pmlStaffs.splice(this.pmlStaffs.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        queryString ? queryString += `&limit=100` : queryString += `?limit=100`;
        const url = `${this.env.getCurrentUrl()}/pml/staff${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }


    async recordCreate(record: PmlStaff): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmlStaff, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async employmentUpdate(record: PmlStaff, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff/employment/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('employmentUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }


    async approvalUpdate(record: PmlStaff, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff/approval/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('approvalUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmlStaff): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmlStaff): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async sendOTP(record: any): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/staff/otp`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                console.log(res);
                if (res.success) {
                    console.log('OTP sent');
                } else {
                    throwError(res.message);
                }
                return res;
            }));
        return await proRes.toPromise();
    }
}
