import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiService, EnvService, AuthService } from "../../services";
import { PmtReservation, ApiResponse, Staff, PmtTerminal } from "../../models";
import { ID } from "../../helpers";

@Injectable()
export class PmtReservations {
  pmtReservations: PmtReservation[] = [];
  user: Staff;
  terminalId = PmtTerminal["id"];

  constructor(
    authService: AuthService,
    private apiService: ApiService,
    private env: EnvService
  ) {
    this.user = authService.getUser();
    this.terminalId = this.user.terminal.id;
  }

  query(params?: any) {
    if (!params) {
      return this.pmtReservations;
    }
    return this.pmtReservations.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: PmtReservation) {
    this.pmtReservations.push(record);
  }

  delete(record: PmtReservation) {
    this.pmtReservations.splice(this.pmtReservations.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
        map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtReservation): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtSchedule,pmtRoute,customer,createdBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmtReservation, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtSchedule,pmtRoute,customer,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: PmtReservation): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: PmtReservation): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async boardReservation(record: PmtReservation) {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations/operation/board/${record.code}`;
    const proRes = this.apiService.updateApi(url, {}).pipe(
      map((res: ApiResponse) => {
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async checkPaymentStatus(record: PmtReservation) {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-reservations/verify/${record.code}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => {
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
