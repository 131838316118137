import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlBilling, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';
import { safeGet } from '../../helpers';


@Injectable()
export class PmlBillings {

  pmlBillings: PmlBilling[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    const queryString = `?sort=name&populate=createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.pmlBillings = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.pmlBillings;
    }
    return this.pmlBillings.filter((pmlBilling) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = pmlBilling[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return pmlBilling;
            } else if (field === params[key]) {
              return pmlBilling;
            }
          }
      }
      return null;
    });
  }


  add(record: PmlBilling) {
    this.pmlBillings.push(record);
  }

  delete(record: PmlBilling) {
      this.pmlBillings.splice(this.pmlBillings.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/pml-billings${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmlBilling): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/pml/pml-billings`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
            console.log('recordCreate() successful =>', res.payload);
            this.add(res.payload);
            return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: PmlBilling, payload): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/pml/pml-billings/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
            this.delete(record);
            this.add(res.payload);
            return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: PmlBilling): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/pml-billings/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlBilling): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/pml-billings/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }


  getSettings(arrObj: Array<any>, value: string) {
    if (Object.keys(arrObj).length > 1 && arrObj) {
      const Obj = arrObj.find(item => item.name === value);
      return (Obj && safeGet(Obj, 'value')) ? Obj.value : 0;
    }
    return 0;
  }

  estimateBilling(shipment) {
    try {
      if (shipment.worth < 100) throw new Error("Invalid Shipment Worth");
      const travelHour = safeGet(shipment, 'travelHour') ? shipment.travelHour : 1;
      const expectedDate = safeGet(shipment, 'expectedDate') ? shipment.expectedDate : Date.now();
      const departureDate = safeGet(shipment, 'departureDate') ? shipment.departureDate : Date.now();
      const urgency = this.getUrgency(travelHour, departureDate, expectedDate);
      console.log('Urgency ============= ' + urgency);
      if (urgency > 0 && urgency <= 1.0) {
        shipment.urgency = urgency;
      } else {
        shipment.urgency = 0;
      }
      const billing = this.pmlBillings;
      console.log(billing);
      if (!billing) throw new Error(`Error No shipment billing matrice data`);
      const baseCharge = this.getSettings(billing, 'BASE_CHARGE');
      const iIndexCoef = this.getSettings(billing, 'INDEX_COEFFICIENT');
      const iWorth = this.getSettings(billing, 'WORTH');
      const iUrgency = this.getSettings(billing, 'URGENCY');
      const iDistance = this.getSettings(billing, 'DISTANCE');
      const iMass = this.getSettings(billing, 'MASS');
      const iVolume = this.getSettings(billing, 'VOLUME');
      const iCombustible = this.getSettings(billing, 'IS_COMBUSTIBLE');
      const iUnique = this.getSettings(billing, 'IS_UNIQUE');
      const iOdoferous = this.getSettings(billing, 'IS_ODIFEROUS');
      const iFragile = this.getSettings(billing, 'IS_FRAGILE');
      const iLiquid = this.getSettings(billing, 'IS_LIQUID');
      const iPerishable = this.getSettings(billing, 'IS_PERISHABLE');

      const xWorth = safeGet(shipment, 'worth') ? iWorth * shipment.worth : 0;
      const xUrgency = safeGet(shipment, 'urgency') ? (2 ** iUrgency) * urgency : 0;
      const xDistance = safeGet(shipment, 'distance') ? (2 ** iDistance) * shipment.distance : 0;
      const xMass = safeGet(shipment, 'mass') ? (2 ** iMass) * shipment.mass : 0;
      const xVolume = safeGet(shipment, 'volume') ? (2 ** iVolume) * shipment.volume : 0;
      const xCombustible = safeGet(shipment, 'isCombustible') ? (2 ** iCombustible) * this.booleanToInt(shipment.isCombustible) : 0;
      const xUnique = safeGet(shipment, 'isUnique') ? (2 ** iUnique) * this.booleanToInt(shipment.isUnique) : 0;
      const xOdoferous = safeGet(shipment, 'isOdiferous') ? (2 ** iOdoferous) * this.booleanToInt(shipment.isOdiferous) : 0;
      const xFragile = safeGet(shipment, 'isFragile') ? (2 ** iFragile) * this.booleanToInt(shipment.isFragile) : 0;
      const xLiquid = safeGet(shipment, 'isLiquid') ? (2 ** iLiquid) * this.booleanToInt(shipment.isLiquid) : 0;
      const xPerishable = safeGet(shipment, 'isPerishable') ? (2 ** iPerishable) * this.booleanToInt(shipment.isPerishable) : 0;
      const costEstimateFlat = 500.0;
      let costEstimate = baseCharge + xWorth + iIndexCoef * (xUrgency + xDistance + xMass + xVolume
        + xCombustible + xUnique + xOdoferous + xFragile + xLiquid + xPerishable);
      costEstimate = Math.ceil(costEstimate/ 100) * 100;
      return (Number.isFinite(costEstimate) && costEstimate > costEstimateFlat) ? costEstimate : costEstimateFlat;
    } catch (err) {
      throw new Error(`Error estimating shipment billing. ${err.message}`);
    }
  }

  booleanToInt(val) {
    if (val === 'true' || val === true) {
      return 1;
    }
    return 0;
  }

  getUrgency(travelHour, departureDate, expectedDate) {
    const diffHours = Math.abs(new Date(departureDate).getTime() - new Date(expectedDate).getTime()) / 3600000;
    return parseFloat((travelHour / diffHours).toFixed(3));
  }

}
